// Images
import valve0_0 from '../assets/images/valves/valve0_0.jpg';
import valve1_0 from '../assets/images/valves/valve1_0.jpg';
import valve2_0 from '../assets/images/valves/valve2_0.jpg';
import valve2_1 from '../assets/images/valves/valve2_1.jpg';
import valve3_0 from '../assets/images/valves/valve3_0.jpg';
import valve3_1 from '../assets/images/valves/valve3_1.jpg';
import valve4_0 from '../assets/images/valves/valve4_0.jpg';
import valve5_0 from '../assets/images/valves/valve5_0.jpg';
import valve5_1 from '../assets/images/valves/valve5_1.jpg';
import valve6_0 from '../assets/images/valves/valve6_0.jpg';
import valve7_0 from '../assets/images/valves/valve7_0.jpg';
import valve8_0 from '../assets/images/valves/valve8_0.jpg';

export const valves = {
  0: {
    product: 'Bola Jardín',
    description: [],
    images: [
      valve0_0,
      valve0_0,
    ],
    valveChildrenId: 0,
  },
  1: {
    product: 'Bola',
    description: [],
    images: [
      valve1_0,
      valve1_0,
    ],
    valveChildrenId: 1,
  },
  2: {
    product: 'Chapaleta',
    description: [],
    images: [
      valve2_0,
      valve2_1,
    ],
    valveChildrenId: 2,
  },
  3: {
    product: 'Check',
    description: [],
    images: [
      valve3_0,
      valve3_1,
    ],
    valveChildrenId: 3,
  },
  4: {
    product: 'Check con canastillo',
    description: [],
    images: [
      valve4_0,
      valve4_0,
    ],
    valveChildrenId: 4,
  },
  5: {
    product: 'Compuerta',
    description: [],
    images: [
      valve5_0,
      valve5_1,
    ],
    valveChildrenId: 5,
  },
  6: {
    product: 'Pie',
    description: [],
    images: [
      valve6_0,
      valve6_0,
    ],
    valveChildrenId: 6,
  },
  7: {
    product: 'Jardín con vástago',
    description: [],
    images: [
      valve7_0,
      valve7_0,
    ],
    valveChildrenId: 7,
  },
  8: {
    product: 'Varilla',
    description: [],
    images: [
      valve8_0,
      valve8_0,
    ],
    valveChildrenId: 8,
  }
}
