import React, { useState, useCallback } from 'react';

// import { useHistory } from "react-router-dom";

import '../styles/LandingStyles.scss';

import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Navbar from "../components/Navbar";

import dropWater from '../assets/images/drop-water.jpg';
import agricultural from '../assets/images/agricultural.jpg';
import hardwareStore from '../assets/images/hardware-store.jpg';
import mining from '../assets/images/mining.jpg';
import garden from '../assets/images/garden.jpg';
import salmonFarm from '../assets/images/salmon-farm.jpg';

import {
  BiMessageRoundedCheck
} from 'react-icons/bi';

import {
  FaRegHandshake,
} from 'react-icons/fa';

function LandingView() {
  // const history = useHistory();
  const [showContactUs, setShowContactUs] = useState(false);

  const setShowContactUsCallback = useCallback((value) => {
    setShowContactUs(value);
  }, []);

  const industries = useCallback((id) => {
    if (id === 0) return {
      name: 'Agricultura',
      image: agricultural,
    };
    else if (id === 1) return {
      name: 'Ferreterías',
      image: hardwareStore,
    };
    else if (id === 2) return {
      name: 'Minería',
      image: mining,
    };
    else if (id === 3) return {
      name: 'Jardines',
      image: garden,
    };
    return {
      name: 'Salmoneras',
      image: salmonFarm,
    };
  }, []);

  const differenceItems = useCallback((id) => {
    if (id === 0) return {
      icon: <FaRegHandshake className="icon" />,
      name: 'Compromiso',
      description: 'Queremos formar parte del desarrollo de nuestros clientes. '+
        'Innovando, buscando la mejor calidad y servicio',
    };
    return {
      icon: <BiMessageRoundedCheck className="icon" />,
      name: 'Sugerencias',
      description: 'Valoramos mucho sus sugerencias para continuar mejorando. Lo invitamos a contactarnos',
    };
  }, []);

  return (
    <div className="app">
      <Navbar
        setShowContactUs={setShowContactUsCallback}
        isLanding
      />
      <div className="landing-container">
        <div className="image-slogan-container">
          <img
            alt="Gota de agua"
            src={dropWater}
            className="drop-image"
          />
          <p className="slogan">
            .....conduciendo el agua
          </p>
        </div>
        <div className="industries-container" id="industries">
          <p className="title">
            industrias
          </p>
          <div className="images-container">
            {[0, 1, 2, 3, 4].map((id) => (
              <div 
                key={id}
                className="image-container"
              >
                <img
                  alt="Agricultua"
                  src={industries(id).image}
                  className="image"
                />
                <p className="description">
                  {industries(id).name}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="difference-container" id="about-us">
          <p className="title">
            ¿quiénes somos?
          </p>
          <p className="alfa-description">
            ALFAPRODUCTS nace como una propuesta de servicio a través de la distribución especializada
            de productos relacionados a la conducción de agua.
            Fundada en 2018 por profesionales de más de 25 años de experiencia en el rubro,
            estamos presentes en la agricultura, salmonicultura, minería y mercado ferretero.
          </p>
          <div className="items-container">
            {[0, 1].map((key) => (
              <div
                key={key.toString()}
                className="item-container"
              >
                {differenceItems(key).icon}
                <p className="name">
                  {differenceItems(key).name}
                </p>
                <p className="description">
                  {differenceItems(key).description}
                </p>
              </div>
            ))}
          </div>
        </div>
        <Footer
          setShowContactUs={setShowContactUsCallback}
        />
      </div>
      {(showContactUs) && (
        <ContactUs
          setShowContactUs={setShowContactUsCallback}
        />
      )}
    </div>
  );
}

export default LandingView;
