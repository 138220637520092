export const valvesChildren = {
  0: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2 x 3/4', '3/4 * 1', '1 * 1-1/4']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16', '16']
      },
    ],
    dimensions: [
      {
        name: 'A',
        values: [93, 102, 120]
      },
      {
        name: 'B',
        values: [56, 60, 68]
      },
      {
        name: 'C',
        values: [39, 43, 54]
      },
      {
        name: 'D',
        values: [95, 95, 128]
      },
      {
        name: 'DN',
        values: [10, 12, 14.8]
      },
      {
        name: 'PN',
        values: [16, 16, 16]
      },
    ],
  },
  1: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2', '3/4', '1', '1-1/4', '1-1/2', '2', '2-1/2', '3', '4']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['25', '25', '25', '25', '25', '25', '25', '25', '25']
      },
    ],
    dimensions: [
      {
        name: 'A',
        values: [48.4, 56, 65, 76, 85, 99, 132, 152, 178]
      },
      {
        name: 'B',
        values: [29.5, 35.5, 42.7, 53, 63, 79, 102.5, 120.5, 144.5]
      },
      {
        name: 'C',
        values: [54.2, 57.6, 60.6, 75.8, 81.3, 88.5, 121.5, 129.5, 141]
      },
      {
        name: 'D',
        values: [95, 95, 126, 140, 140, 165, 224, 224, 283.5]
      },
      {
        name: 'E',
        values: [14.8, 19, 24, 30.2, 37, 47, 62, 74, 90]
      },
      {
        name: 'PN',
        values: [25, 25, 25, 25, 25, 25, 25, 25, 25]
      },
    ],
  },
  2: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2', '3/4', '1', '1-1/4', '1-1/2', '2', '2-1/2', '3', '4']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16', '16', '16', '16', '16', '16', '16', '16']
      },
    ],
    dimensions: [
      {
        name: 'A',
        values: [47.5, 56, 63, 71, 80.5, 93, 105, 123, 162]
      },
      {
        name: 'C',
        values: [34.5, 39, 46.5, 51, 59.2, 67, 77, 81, 101]
      },
      {
        name: 'DN',
        values: [14, 18, 22, 28, 33.5, 43, 48.5, 64, 83]
      },
      {
        name: 'PN',
        values: [16, 16, 16, 16, 16, 16, 16, 16, 16]
      },
    ],
  },
  3: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2', '3/4', '1', '1-1/4', '1-1/2', '2', '2-1/2', '3', '4']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16', '16', '16', '16', '16', '16', '16', '16']
      },
    ],
    dimensions: [
      {
        name: 'A',
        values: [42.5, 52, 57.5, 62.5, 71, 78.5, 93, 100, 112]
      },
      {
        name: 'C',
        values: [35.5, 41.5, 47, 58.7, 66.5, 82, 95, 110, 143]
      },
      {
        name: 'DN',
        values: [18, 23, 29, 36, 42.5, 53.5, 66, 79, 99]
      },
      {
        name: 'PN',
        values: [16, 16, 16, 16, 16, 16, 16, 16, 16]
      },
    ],
  },
  4: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2', '3/4', '1', '1-1/4', '1-1/2', '2', '2-1/2', '3', '4']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16', '16', '16', '16', '16', '16', '16', '16']
      },
    ],
    dimensions: [
      {
        name: 'A',
        values: [86.5, 102, 116.5, 127.5, 144, 156, 204, 225, 249]
      },
      {
        name: 'C',
        values: [35.5, 41.5, 47, 58.7, 66.5, 82, 95, 110, 143]
      },
      {
        name: 'PN',
        values: [16, 16, 16, 16, 16, 16, 16, 16, 16]
      },
    ],
  },
  5: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2', '3/4', '1', '1-1/4', '1-1/2', '2', '2-1/2', '3', '4']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16', '16', '16', '16', '16', '16', '16', '16']
      },
    ],
    dimensions: [
      {
        name: 'A',
        values: [56, 56, 60, 79, 79, 90, 99, 108, 130]
      },
      {
        name: 'B',
        values: [12, 12.5, 14, 15, 14, 16, 22, 22, 22.5]
      },
      {
        name: 'H',
        values: [65, 68, 78, 95, 104, 119, 165, 175, 210]
      },
      {
        name: 'L',
        values: [41, 43, 48, 53, 54, 61, 78, 83, 91]
      },
      {
        name: 'E',
        values: [14, 15, 19.5, 25, 31, 40, 53, 60, 75]
      },
      {
        name: 'PN',
        values: [16, 16, 16, 16, 16, 16, 16, 16, 16]
      },
    ]
  },
  6: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1', '1-1/4', '1-1/2', '2']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16', '16', '16']
      },
    ],
    dimensions: [
      {
        name: 'A',
        values: [82, 94.5, 102, 119.5]
      },
      {
        name: 'B',
        values: [51, 61, 68.5, 80]
      },
      {
        name: 'C',
        values: [14, 16, 16, 18.5]
      },
      {
        name: 'PN',
        values: [16, 16, 16, 16]
      },
    ],
  },
  7: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2 * 3/4', '3/4 * 1']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16']
      },
    ],
    dimensions: [],
  },
  8: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1/2', '3/4', '1', '1-1/4', '1-1/2', '2']
      },
      {
        name: 'Presión Nominal',
        measure: 'Bar',
        values: ['16', '16', '16', '16', '16', '16']
      },
    ],
    extra: {
      product: 'Flotador de PE',
      name: 'Medida',
      measure: 'Pulg',
      values: ['4 - 1/2', '5', '6']
    },
    dimensions: [
      {
        name: 'A',
        values: [102, 108, 137]
      },
      {
        name: 'B',
        values: [7, 7, 9]
      },
      {
        name: 'C',
        values: [114, 127, 152]
      },
      {
        name: 'D',
        values: ['M8', 'M8', 'M10']
      },
      {
        name: 'E',
        values: [6, 9, 13]
      },
      {
        name: 'L',
        values: [270, 270, 310]
      },
      {
        name: 'PN',
        values: [16, 16, 16]
      },
    ],
  },
}
