import React, { memo } from 'react';

// Styles
import '../styles/AlertStyles.scss';

function Alert(props) {
  // Props
  const {
    children,
    buttonText,
    buttonFunction,
  } = props;

  return (
    <div className="alert-view">
      <div className="alert-container">
        {children}
        <button
          className="button"
          type="button"
          onClick={() => buttonFunction()}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export default memo(Alert);
