import React, { useState } from 'react';

import dropWater from '../assets/images/drop-water.jpg';
import logoAlfaproducts from '../assets/images/logos/alfa-products.png';
import product1 from '../assets/images/families/family0_2.jpg';
import product2 from '../assets/images/valves/valve1_0.jpg';
import product3 from '../assets/images/irrigation/irrigation2_0.jpg';
import product4 from '../assets/images/irrigation/irrigation1_1.jpg';

import {
  FaWhatsapp,
  FaRegEnvelope,
} from 'react-icons/fa';

import {
  GoLocation,
} from 'react-icons/go';

import {
  TfiWorld,
} from 'react-icons/tfi';

import {
  BsChevronDown,
} from 'react-icons/bs';

import '../styles/LandingMobileStyles.scss';

function LandingMobileView() {
  const [isCopied, setIsCopied] = useState(false);

  async function copyText() {
    // eslint-disable-next-line max-len
    const text = `Salvador Hurtado Parot\n+56 9 98225782\nshurtado@alfaproducts.cl\nwww.alfaproducts.cl\nLos Peumos 10200, Lo Barnechea, Santiago, Chile, 7700836`;
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleSaveInfo = () => {
    copyText()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
  };

  return (
    <div className="landing-mobile-container">
      <div className="info-container">
        <img alt="Alfaproducts" className="logo" src={logoAlfaproducts} />
        <p className="name">Salvador Hurtado Parot</p>
        <p className="position">General Manager</p>
        <div className="separator-container">
          <div className="left">
            <p><FaWhatsapp className="icon"/>+56 9 98225782</p>
            <p><FaRegEnvelope className="icon"/>shurtado@alfaproducts.cl</p>
            <p><TfiWorld className="icon"/>www.alfaproducts.cl</p>
          </div>
          <hr className="separator" />
          <div className="right">
            <p><GoLocation className="icon"/>Los Peumos 10200</p>
            <p className="margin">Lo Barnechea, Santiago, Chile, 7700836</p>
          </div>
        </div>
        <div className="save-contact" onClick={handleSaveInfo}>
          {(isCopied) ? 'Copied!' : 'Copy contact'}
        </div>
        <BsChevronDown className="down-icon" onClick={() => window.location.href = '#images'} />
      </div>
      <div className="images" id="images">
        <img alt="Alfaproducts" className="logo" src={logoAlfaproducts} />
        <img alt="Gota de agua" src={dropWater} className="image" />
        <img alt="Alfaproducts" className="image" src={product1} />
        <img alt="Alfaproducts" className="image" src={product2} />
        <img alt="Alfaproducts" className="image" src={product3} />
        <img alt="Alfaproducts" className="image" src={product4} />
      </div>
    </div>
  );
}

export default LandingMobileView;