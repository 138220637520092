import React, { useCallback, useState, memo } from 'react';

import { useNavigate } from "react-router-dom";

import '../styles/NavbarStyles.scss';

import logoAlfaproducts from '../assets/images/logos/alfa-products.png';

import {
  families,
  valves,
  irrigation,
} from '../constants';

const initalActiveHover = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
}

function Navbar(props) {
  const {
    setShowContactUs,
    isLanding=false,
  } = props;

  const navigate = useNavigate();

  const [showDropdownFamilies, setDropdownFamilies] = useState(false);
  const [showDropdownValves, setDropdownValves] = useState(false);
  const [showDropdownIrrigation, setDropdownIrrigation] = useState(false);
  const [activeHover, setActiveHover] = useState(initalActiveHover)

  const  changeHoverFunction = useCallback((id) => {
    const activeHoverCopy = { ...initalActiveHover };
    activeHoverCopy[id] = true;
    setActiveHover(activeHoverCopy);
  }, []);

  return (
    <div className="navbar">
      <img
        alt="Alfaproducts logo"
        src={logoAlfaproducts}
        className="logo"
        onClick={() => navigate('/')}
      />
      <div className="menu-container">
        <div
          className="menu-option"
          onMouseEnter={() => {
            setDropdownFamilies(true);
            changeHoverFunction(0)
          }}
          onMouseLeave={() => {
            setDropdownFamilies(false);
            setActiveHover(initalActiveHover)
          }}
        >
          <p className="value">
            mangueras
          </p>
          {(activeHover[0]) && (
            <div className="border-bottom" />
          )}
          {(showDropdownFamilies) && (
            <div className="dropdown-menu">
              {Object.keys(families).map((key) => (
                <div
                  key={key.toString()}
                  className="dropdown-menu-option"
                  onClick={() => navigate(`/product/hoses/${key}/`)}
                >
                  <p className="dropdown-menu-option-text">
                    {families[key].product}
                  </p>
                  <img src={families[key].images[0]} alt="Alfaproducts" />
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="menu-option"
          onMouseEnter={() => {
            setDropdownValves(true);
            changeHoverFunction(1);
          }}
          onMouseLeave={() => {
            setDropdownValves(false);
            setActiveHover(initalActiveHover);
          }}
        >
          <p className="value">
            válvulas
          </p>
          {(activeHover[1]) && (
            <div className="border-bottom" />
          )}
          {(showDropdownValves) && (
            <div className="dropdown-menu">
              {Object.keys(valves).map((key) => (
                <div
                  key={key.toString()}
                  className="dropdown-menu-option"
                  onClick={() => navigate(`/product/valves/${key}/`)}
                >
                  <p className="dropdown-menu-option-text">
                    {valves[key].product}
                  </p>
                  <img src={valves[key].images[0]} alt="Alfaproducts" />
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="menu-option"
          onMouseEnter={() => {
            setDropdownIrrigation(true);
            changeHoverFunction(2);
          }}
          onMouseLeave={() => {
            setDropdownIrrigation(false);
            setActiveHover(initalActiveHover);
          }}
        >
          <p className="value">
            riego tecnificado
          </p>
          {(activeHover[2]) && (
            <div className="border-bottom" />
          )}
          {(showDropdownIrrigation) && (
            <div className="dropdown-menu">
              {Object.keys(irrigation).map((key) => (
                <div
                  key={key.toString()}
                  className="dropdown-menu-option"
                  // onClick={() => window.location.href = `/product/irrigation/${key}/`}
                  onClick={() => navigate(`/product/irrigation/${key}/`)}
                >
                  <p className="dropdown-menu-option-text">
                    {irrigation[key].product}
                  </p>
                  <img src={irrigation[key].images[0]} alt="Alfaproducts" />
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="menu-option"
          onClick={() => (isLanding) ? window.location.href = '#industries' : navigate('/')}
          onMouseEnter={() => changeHoverFunction(3)}
          onMouseLeave={() => setActiveHover(initalActiveHover)}
        >
          <p className="value">
            quiénes somos
          </p>
          {(activeHover[3]) && (
            <div className="border-bottom" />
          )}
        </div>
        <div
          className="menu-option"
          onMouseEnter={() => changeHoverFunction(4)}
          onMouseLeave={() => setActiveHover(initalActiveHover)}
        >
          <p
            className="value"
            onClick={() => setShowContactUs(true)}
          >
            contáctanos
          </p>
          {(activeHover[4]) && (
            <div className="border-bottom" />
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(Navbar);
