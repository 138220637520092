import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../styles/ProductsStyles.scss';

import { irrigation } from '../constants';


import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import LogoRain from '../assets/images/logos/rain.png';

import { BsDot } from "react-icons/bs";

function IrrigationView() {
  // Params
  const { id } = useParams();

  // States
  const [mounted, setMounted] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);

  const setShowContactUsCallback = useCallback((value) => {
    setShowContactUs(value);
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (mounted) && (
    <div className="product-container">
      <Navbar
        setShowContactUs={setShowContactUsCallback}
      />
      <div className="image-title">
        <Carousel className="carousel">
          {irrigation[id].images.map((image, i) => (
            <img
              key={i}
              src={image}
              alt="Alfaproducts"
            />
          ))}
        </Carousel>
        <div className="info">
          <img
            alt="Rain logo"
            src={LogoRain}
            className="logo"
          />
          <p className="product-name">
            {irrigation[id].product}
          </p>
          {irrigation[id].description.map((text, i) => (
            <div
              key={i}
              className="text"
            >
              <BsDot />
              {text}
            </div>
          ))}
        </div>
      </div>
      <div className="product-description">
        <p className="title">
          Descripción del producto:
        </p>
        {irrigation[id].characteristics.map((text, i) => (
          <p
            key={i}
            className="text"
          >
            <BsDot />
            {text}
          </p>
        ))}
      </div>
      <Footer
        setShowContactUs={setShowContactUsCallback}
      />
      {(showContactUs) && (
        <ContactUs
          setShowContactUs={setShowContactUsCallback}
        />
      )}
    </div>
  );
}

export default IrrigationView;