/* eslint-disable max-len */

// Family: { id[int]: data[familyData] }
// familyData: { product[string], description[string][], images[], familyChildrenId[int] }

// Images
import family0_0 from '../assets/images/families/family0_0.jpg';
import family0_1 from '../assets/images/families/family0_1.jpg';
import family0_2 from '../assets/images/families/family0_2.jpg';
import family0_3 from '../assets/images/families/family0_3.jpg';
import family1_0 from '../assets/images/families/family1_0.jpg';
import family1_1 from '../assets/images/families/family1_1.jpg';
import family1_2 from '../assets/images/families/family1_2.jpg';
import family1_3 from '../assets/images/families/family1_3.jpg';
import family2_0 from '../assets/images/families/family2_0.jpg';
import family2_1 from '../assets/images/families/family2_1.jpg';
import family2_2 from '../assets/images/families/family2_2.jpg';
import family2_3 from '../assets/images/families/family2_3.jpg';
import family3_0 from '../assets/images/families/family3_0.jpg';
import family3_1 from '../assets/images/families/family3_1.jpg';
import family3_2 from '../assets/images/families/family3_2.jpg';
import family4_0 from '../assets/images/families/family4_0.jpg';
import family4_1 from '../assets/images/families/family4_1.jpg';
import family4_2 from '../assets/images/families/family4_2.jpg';

export const families = {
  0: {
    product: 'Manguera Espiralada (succión y descarga)',
    description: [
      'Manguera Espiralada amarilla para succión y descarga.',
      'El espiral rígido le permite succionar y descargar líquidos sin colapsar. Presenta superficie interior lisa.',
      'Tempratura de trabajo entre -10° y 50° celsius.',
      'Radio mínimo de curvatura del rollo hasta 4 diámetros.'
    ],
    images: [
      family0_0,
      family0_1,
      family0_2,
      family0_3,
    ],
    familyChildrenId: 0,
  },
  1: {
    product: 'Manguera Plana (3 bar)',
    description: [
      'Manguera de muy buena relación de espesor y resistencia a la presión.',
      'Fabricada con tejido interior de poliéster con cobertura de PVC flexible interior y exterior.',
      'Tempratura de trabajo entre -10° y 50° celsius.',
      'Principal aplicación en riegos móviles por aspersión, cabezal para cinta de riego, descargas de líquidos.'
    ],
    images: [
      family1_0,
      family1_1,
      family1_2,
      family1_3,
    ],
    familyChildrenId: 1,
  },
  2: {
    product: 'Manguera Jardín Reforzada',
    description: [
      'Manguera flexible y resistente de 3 capas (Capa 1: PVC flexible, Capa 2: Tejido de Poliéster, Capa 3: PVC flexible).',
      'Además del tejido romboide, se incluyen 6 o 7 hilos longitudinales para mejorar  la tracción de la manguera.',
      'Aplicación recomendada en riego de jardines y parques. Uso domiciliario.'
    ],
    images: [
      family2_0,
      family2_1,
      family2_2,
      family2_3,
    ],
    familyChildrenId: 2,
  },
  3: {
    product: 'Manguera Presión',
    description: [
      'Manguera flexible y resistente de 3 capas (Capa 1: PVC flexible, Capa 2: Tejido de Poliéster, Capa 3: PVC flexible).',
      'Además del tejido romboide, se incluyen 6 o 7 hilos longitudinales para mejorar  la tracción de la manguera.',
      'La Manguera está impresa con la marca ALFAHOSES a cada metro e indicando el metraje dentro del rollo.',
      'Aplicación recomendada en conducción de líquidos y aire.',
      'Temperatura de operación entre -10° y 50° Celsius.'
    ],
    images: [
      family3_0,
      family3_1,
      family3_2,
      family3_0,
    ],
    familyChildrenId: 3,
  },
  4: {
    product: 'Manguera Nivel',
    description: [
      'Manguera flexible y transparente de 1/2" de diámetro.',
      'Impresa metro a metro.',
      'Rollo de 100 metros'
    ],
    images: [
      family4_0,
      family4_1,
      family4_2,
      family4_0,
    ],
    familyChildrenId: 4,
  }
}
