/* eslint-disable max-len */

// Images
import irrigation0_0 from '../assets/images/irrigation/irrigation0_0.jpg';
import irrigation0_1 from '../assets/images/irrigation/irrigation0_1.jpg';
import irrigation1_0 from '../assets/images/irrigation/irrigation1_0.jpg';
import irrigation1_1 from '../assets/images/irrigation/irrigation1_1.jpg';
import irrigation2_0 from '../assets/images/irrigation/irrigation2_0.jpg';
import irrigation2_1 from '../assets/images/irrigation/irrigation2_1.jpg';
import irrigation3_0 from '../assets/images/irrigation/irrigation3_0.jpg';
import irrigation3_1 from '../assets/images/irrigation/irrigation3_1.jpg';
import irrigation4_0 from '../assets/images/irrigation/irrigation4_0.jpg';
import irrigation4_1 from '../assets/images/irrigation/irrigation4_1.jpg';
import irrigation4_2 from '../assets/images/irrigation/irrigation4_2.jpg';
import irrigation5_0 from '../assets/images/irrigation/irrigation5_0.jpg';
import irrigation5_1 from '../assets/images/irrigation/irrigation5_1.jpg';
import irrigation5_2 from '../assets/images/irrigation/irrigation5_2.jpg';
import irrigation5_3 from '../assets/images/irrigation/irrigation5_3.jpg';
import irrigation6_0 from '../assets/images/irrigation/irrigation6_0.jpg';
import irrigation6_1 from '../assets/images/irrigation/irrigation6_1.jpg';
import irrigation7_0 from '../assets/images/irrigation/irrigation7_0.jpg';
import irrigation7_1 from '../assets/images/irrigation/irrigation7_1.jpg';
import irrigation7_2 from '../assets/images/irrigation/irrigation7_2.jpg';


export const irrigation = {
  0: {
    product: 'Programador a batería CLICK',
    description: [
      'Click: controlador de toque con programación fácil e intuitiva',
      'Fácil de programar y fácil de utilizar',
      'El controlador CLICK sigue los simples pasos: Cuándo, cuanto y con que frecuencia regar',
      'Conexión directa a la llave de jardín'
    ],
    characteristics: [
      'Tiempo de riego: 2-5-10-15 min',
      'Frecuencia de riego: 12-24-48 hrs',
      'Entrada HI 3/4" o 1" - Salida HE 3/4"',
      'Presión de trabjo: 1.0 - 5.0 bar',
      'Rango de caudal: 10 - 35 l/min',
      'Material de fabricación: ABS',
      'Funciona con 2 pilas AAA (no incluidas)',
      'Color verde y negro',
      'Origen: Italia'
    ],
    images: [
      irrigation0_0,
      irrigation0_1,
      irrigation0_0,
      irrigation0_1,
    ]
  },
  1: {
    product: 'I-DIAL INDOOR 24 VAC',
    description: [
      'I-DIAL es un nuevo y exclusivo controlador electrónico de 6-8-12 estaciones exteriores para aplicaciones residenciales',
      'Sigue la filosofía de los controladores Rain y permite configurar fácilmente la programación en 3 pasos: hora de inicio, duración y frecuencia.'
    ],
    characteristics: [
      '4 programas - 1 salida para cada programa',
      'Tiempo de ejecución min/max: 1/240 minutos',
      'Frecuencia de riego: Calendario de 7 días con selección de días individuales, o intervalos de riego de 1 a 19 días',
      'Presupuestación de agua del 10% al 200%',
      'Indicación de siguiente riego programado',
      'Cuenta regresiva de riego',
      'Función de batería baja',
      'Input de sensor de lluvia',
      'Amplia pantalla LCD retroiluminada',
      'Memoria permanente que opermite guardar prgoramas durante cortes de energía',
      'Transformador ecterno - Fuente de alimentación: 220 VAC 50-60 Hz - Salidas eléctricas: 24 VAC, 0.8 AMP',
      'Origen: Italia'
    ],
    images: [
      irrigation1_0,
      irrigation1_1,
      irrigation1_0,
      irrigation1_1,
    ]
  },
  2: {
    product: 'I-DIAL OUTDOOR 24 VAC',
    description: [
      'I-DIAL es un nuevo y exclusivo controlador electrónico de 6-8-12 estaciones exteriores para aplicaciones residenciales',
      'Sigue la filosofía de los controladores Rain y permite configurar fácilmente la programación en 3 pasos: hora de inicio, duración y frecuencia.'
    ],
    characteristics: [
      '4 programas - 1 salida para cada programa',
      'Tiempo de ejecución min/max: 1/240 minutos',
      'Frecuencia de riego: Calendario de 7 días con selección de días individuales, o intervalos de riego de 1 a 19 días',
      'Presupuestación de agua del 10% al 200%',
      'Indicación de siguiente riego programado',
      'Cuenta regresiva de riego',
      'Función de batería baja',
      'Input de sensor de lluvia',
      'Amplia pantalla LCD retroiluminada',
      'Cubierta protectora',
      'Memoria permanente que opermite guardar prgoramas durante cortes de energía',
      'Transformador ecterno - Fuente de alimentación: 220 VAC 50-60 Hz - Salidas eléctricas: 24 VAC, 1 AMP',
      'Origen: Italia'
    ],
    images: [
      irrigation2_0,
      irrigation2_1,
      irrigation2_0,
      irrigation2_1,
    ]
  },
  3: {
    product: 'Válvula Solenoide',
    description: [
      'Electroválvula Rain 1" con control de flujo',
      'Adecuada para pequeños y medianos jardines residenciales o profesionales, y para uso agrícola de bajo y alto caudal con bajas y altas presiones'
    ],
    characteristics: [
      'Construida en materiales resistentes y duraderos',
      'Diafragma construido en una pieza para un cierre confiable y sin fugas',
      'Solenoide con certificación IP-68',
      'Interruptor para encendido manual',
      'Posee control de flujo',
      'Solenoide estándar 24 VAC',
      'Presiones de trabajo min / max: 1.0 / 12.0 bar',
      'Temperatura: +4º -70º',
      'Flujo de trabajo: 20-50 L/min',
      'Cierre de válvula sin fugas',
      'Origen: Italia'
    ],
    images: [
      irrigation3_0,
      irrigation3_1,
      irrigation3_0,
      irrigation3_1,
    ]
  },
  4: {
    product: 'Cajas de válvulas',
    description: [
      'Con sistema EZ-Open, una idea sencilla que ha cambiado la forma de abrir las cajas de válvulas',
      'Las nuevas cajas de válvulas se abren simplemente levantando y tirando una maneta incrustada en la tapa',
      'Cada caja tiene un cerrojo para mayor seguridad',
      'El diseño permite una instalación fácil y ordenada de los cables',
      'Ideal para instalación subterránea de manifold de válvulas'
    ],
    characteristics: [
      'Cajas circulares (diámetros): 6"/15cm y 10"/25cm',
      'Cajas rectangulares (medidas): 39x28cm y 39x55cm',
      'Material del cuerpo negro: polipropileno (PP)',
      'Material tapa: polietileno (PEHD)',
      'Manija de apertura',
      'Tornillo de cierre seguro',
      'Origen: Italia'
    ],
    images: [
      irrigation4_0,
      irrigation4_1,
      irrigation4_2,
      irrigation4_0,
    ]
  },
  5: {
    product: 'Aspersor pop up S020',
    description: [
      'Aspersor delgado con boquillas preinstaladas',
      'Los aspersores S020 son ideales para el césped, la cobertura del suelo y las áreas de arbustos',
      'Acepta múltiple boquillas ajustables hembras',
      'Resorte de retracción de acero inoxidable',
      'Incluye boquilla de serie 10 o de serie 12'
    ],
    characteristics: [
      'Aspersor pop up 2" con boquilla serie 10 y serie 12',
      'Entrada: 1/2"',
      'Radios: Serie 10A 3.35m; Serie 12A 3.96m',
      'Altura: 5 - 10cm',
      'Rango de ajuste del arco: 0 - 360º',
      'Presión de trabajo: 1.38 - 3.45 bar',
      'Origen: Italia'
    ],
    images: [
      irrigation5_0,
      irrigation5_1,
      irrigation5_2,
      irrigation5_3,
    ]
  },
  6: {
    product: 'Aspersor rotor S050',
    description: [
      'Rain S050 1/2" es un aspersor de tipo rotativo accionado por engranajes',
      'Capaz de cubrir un área de 5.2 a 9.1 metros de radio',
      'Perfecto para áreas pequeñas de césped y jardines',
      'Proporciona un ajuste de arco de 40º - 360'
    ],
    characteristics: [
      'Entrada: 1/2"',
      'Cubierta de goma: sella la suciedad y aumenta la durabilidad del producto',
      'Rango de ajuste del arco: 40º - 360º',
      'Rango del caudal: 2.8 - 14.4 l/m',
      'Presión de trabajo: 2.07 - 3.45 bar',
      'Índice de precipitación: 0.5 a 1 cm/h',
      'Altura total: 15.2 cm',
      'Radio: 5.2 - 9,1 m',
      'Origen: Italia'
    ],
    images: [
      irrigation6_0,
      irrigation6_1,
      irrigation6_0,
      irrigation6_1,
    ]
  },
  7: {
    product: 'Aspersor rotor S075',
    description: [
      'Rain S075 3/4" es un aspersor de tipo rotativo accionado por engranajes',
      'Diseñado para aplicaciones residenciales y comerciales ligeras',
      'Capaz de cubrir un área de 6.7 a 15.5 metros de radio',
      'Proporciona un ajuste de arco de 40º - 360',
      'Se utiliza con una amplia selección de boquillas para ofrecer una máxima flexibilidad al diseño del sistema de riego'
    ],
    characteristics: [
      'Entrada: 3/4"',
      'Cubierta de goma: sella la suciedad y aumenta la durabilidad del producto',
      'Rango de ajuste del arco: 40º - 360º',
      'Rango del caudal: 2.6 - 32.6 l/m',
      'Presión de trabajo: 2.0 - 5.0 bar',
      'Índice de precipitación: 0.3 a 2.9 cm/h',
      'Altura total: 18.7 cm',
      'Radio: 6.7 - 15.5 m',
      'Origen: Italia'
    ],
    images: [
      irrigation7_0,
      irrigation7_1,
      irrigation7_2,
      irrigation7_0,
    ]
  }
}
