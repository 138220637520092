import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../styles/ProductsStyles.scss';

import {  valves, valvesChildren } from '../constants';

import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import LogoAlfavalves from '../assets/images/logos/alfa-valves.png';

import { BsDot } from "react-icons/bs";

function ValvesView() {
  // Params
  const { id } = useParams();

  // States
  const [showContactUs, setShowContactUs] = useState(false);
  const [mounted, setMounted] = useState(false);

  const setShowContactUsCallback = useCallback((value) => {
    setShowContactUs(value);
  }, []);

  const dataTable = () => {
    const table = []
    for (let i = 0; i < valvesChildren[id].data[0].values.length; i++) {
      const row = []
      for (let j = 0; j < valvesChildren[id].data.length; j++) {
        row.push(valvesChildren[id].data[j].values[i]);
      }
      table.push(row)
    }
    return table;
  }

  useEffect(() => {
    setMounted(true);
  }, [id])

  return (mounted) && (
    <div className="product-container">
      <Navbar
        setShowContactUs={setShowContactUsCallback}
      />
      <div className="image-title">
        <Carousel className="carousel">
          {valves[id].images.map((image, i) => (
            <img
              key={i}
              src={image}
              alt="Alfaproducts"
            />
          ))}
        </Carousel>
        <div className="info">
          <img
            alt="Alfavalves logo"
            src={LogoAlfavalves}
            className="logo"
          />
          <p className="product-name">
            {valves[id].product}
          </p>
          {valves[id].description.map((text, i) => (
            <div
              key={i}
              className="text"
            >
              <BsDot />
              {text}
            </div>
          ))}
        </div>
      </div>
      <div className="tables">
        <table className="margin-right">
          <thead>
            <tr>
              {valvesChildren[id].data.map((data, i) => (
                <th
                  key={i}
                  className="header"
                >
                  {`${data.name} (${data.measure})`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTable().map((row, i) => (
              <tr key={i}>
                {row.map((data, j) => (
                  <td className="cell" key={j}>
                    {data}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {(valvesChildren[id].dimensions.length > 0) && (
          <table>
            <thead>
              <tr>
                <th className="header">
                  Dimensión (mm)
                </th>
                {valvesChildren[id].data[0].values.map((data, i) => (
                  <th
                    className="header"
                    key={i}
                  >
                    {data}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {valvesChildren[id].dimensions.map((row, i) => (
                <tr key={i}>
                  <td className="cell">
                      {row.name}
                    </td>
                  {row.values.map((data, j) => (
                    <td className="cell" key={j}>
                      {data}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Footer
        setShowContactUs={setShowContactUsCallback}
      />
      {(showContactUs) && (
        <ContactUs
          setShowContactUs={setShowContactUsCallback}
        />
      )}
    </div>
  );
}

export default ValvesView;
