import React from 'react';

import '../styles/FooterStyles.scss';

import logoAlfaproducts from '../assets/images/logos/alfa-products.png';

function Footer(props) {
  const { setShowContactUs } = props;
  return (
    <div className="main-footer">
      <div className="footer">
        <img
          alt="Alfaproducts logo"
          src={logoAlfaproducts}
          className="logo"
        />
        <div className="alfaproducts-information-container">
          <p className="information">
            Alfaproducts Spa
          </p>
          <p
            className="information contact-us-text"
            onClick={() => setShowContactUs(true)}
          >
            Contáctanos
          </p>
          <p
            className="information"
            onClick={(e) => navigator.clipboard.writeText(e.target.innerHTML)}
          >
            ventas2@alfaproducts.cl
          </p>
        </div>
      </div>
      <div className="gray-bar" />
    </div>
  );
}

export default Footer;
