// familyChildren: { id[int]: data[familyChildrenData]}
// familyChildrenData: { data[tableData][] }
// tableData: {  }

export const familyChildren = {
  0: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg',
        values: ['1', '1-1/4', '1-1/2', '2', '2-1/2', '3', '4', '6', '8']
      },
      {
        name: 'Diám. Interno',
        measure: 'mm',
        values: ['25', '32', '38', '50', '63', '75', '100', '150', '202']
      },
      {
        name: 'Diám. Externo',
        measure: 'mm',
        values: ['32', '40', '46', '59.5', '72', '85', '111', '165', '219']
      },
      {
        name: 'Presión de Trabajo',
        measure: 'PSI',
        values: ['110', '110', '100', '95', '80', '70', '65', '50', '28']
      },
      {
        name: 'Vacío',
        measure: 'mca',
        values: ['7', '7', '7', '7', '7', '7', '7', '6', '5']
      },
      {
        name: 'Peso/m Aproc',
        measure: 'Grs/m',
        values: ['400', '530', '600', '900', '1320', '1400', '2400', '4700', '7160']
      },
      {
        name: 'Largo Rollo',
        measure: 'm',
        values: ['50', '50', '50', '50', '25', '50', '25', '25', '10 (Tira)']
      }
    ]
  },
  1: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg"',
        values: ['1-1/2', '2', '3', '4', '6', '8']
      },
      {
        name: 'Diám. Interno',
        measure: 'mm',
        values: ['40', '50', '75', '100', '150', '200']
      },
      {
        name: 'Presión de Trabajo',
        measure: 'BAR',
        values: ['3', '3', '3', '3', '3', '3']
      },
      {
        name: 'Peso/m Aproc',
        measure: 'Grs/m',
        values: ['173', '208', '337', '453', '860', '1530']
      },
      {
        name: 'Largo Rollo',
        measure: 'm',
        values: ['100', '100', '100', '100', '100', '100']
      }
    ]
  },
  2: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg"',
        values: ['1/2', '3/4', '1']
      },
      {
        name: 'Diám. Interno',
        measure: 'mm',
        values: ['12', '19', '25']
      },
      {
        name: 'Diám. Externo',
        measure: 'mm',
        values: ['16', '24', '30']
      },
      {
        name: 'Presión de Trabajo',
        measure: 'PSI',
        values: ['75', '65', '55']
      },
      {
        name: 'Peso/m Aproc',
        measure: 'Grs/m',
        values: ['110', '200', '262']
      },
      {
        name: 'Largo Rollo',
        measure: 'm',
        values: ['100', '100', '50']
      }
    ]
  },
  3: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg"',
        values: ['3/8', '1/2', '3/4', '1', '1-1/4', '1-1/2']
      },
      {
        name: 'Diám. Interno',
        measure: 'mm',
        values: ['10', '12', '19', '25', '32', '38']
      },
      {
        name: 'Diám. Externo',
        measure: 'mm',
        values: ['14', '16', '24', '30', '38', '45']
      },
      {
        name: 'Presión de Trabajo',
        measure: 'PSI',
        values: ['180', '180', '130', '110', '80', '75']
      },
      {
        name: 'Peso/m Aproc',
        measure: 'Grs/m',
        values: ['90', '115', '220', '270', '410', '560']
      },
      {
        name: 'Largo Rollo',
        measure: 'm',
        values: ['100', '100', '100', '50', '50', '50']
      }
    ]
  },
  4: {
    data: [
      {
        name: 'Medida',
        measure: 'Pulg"',
        values: ['1/2']
      },
      {
        name: 'Diám. Interno',
        measure: 'mm',
        values: ['12']
      },
      {
        name: 'Rollo',
        measure: 'm',
        values: ['100']
      },
    ]
  }
}
