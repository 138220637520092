import React, { useState, memo, useCallback } from 'react';
import emailjs from 'emailjs-com';

// Icons
import {
  VscClose
} from 'react-icons/vsc';

import '../styles/ContactUsStyles.scss';

import emailkeys from '../constants/emailkeys';

import Alert from './Alert';

// eslint-disable-next-line max-len
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

function ContactUs(props) {
  const { setShowContactUs } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [showSucceedMessage, setShowSucceedMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const isValid = useCallback(() => {
    if (name && emailRegex.test(email) && subject && content) return true;

    setShowErrorMessage(true);
    return false;
  }, [name, email, subject, content]);

  const handleChange = useCallback((e) => {
    setName(e.target.value);
  })

  function handleSubmit() {
    if (!isValid()) return;

    const templateParams = {
      name,
      email,
      subject,
      content,
    };

    emailjs.send(emailkeys.SERVICE_ID, emailkeys.TEMPLATE_ID, templateParams, emailkeys.USER_ID)
      .then(() => {
        setShowSucceedMessage(true);
      })
      .catch((err) => {
        console.log('error');
        console.log(err);
      });
  }

  const closeAll = useCallback(() => {
    setShowSucceedMessage(false);
    setShowContactUs(false);
  });

  const closeAlert = useCallback(() => {
    setShowErrorMessage(false);
  });

  if (showSucceedMessage) {
    return (
      <Alert
        buttonFunction={closeAll}
        buttonText="Aceptar"
      >
        <p className="text bold">
          ¡El email se ha enviado con éxito!
        </p>
        <p className="text">
          Lo contactaremos lo antes posible
        </p>
      </Alert>
    );
  }

  if (showErrorMessage) {
    return (
      <Alert
        buttonFunction={closeAlert}
        buttonText="Aceptar"
      >
        <p className="text bold">
          ¡Error al llenar el formulario!
        </p>
        <p className="text">
          {`${(!name) ? 'Nombre ' : ''}`}
          {`${(!emailRegex.test(email)) ? 'Email ' : ''}`}
          {`${(!subject) ? 'Asunto ' : ''}`}
          {`${(!content) ? 'Contenido' : ''}`}
        </p>
        <p className="text">
          Inválido(s)
        </p>
      </Alert>
    );
  }

  return (
    <div className="contact-us-view">
      <div className="contact-us-content">
        <div
          className="close-icon-container"
          onClick={() => {setShowContactUs(false)}}
        >
          <VscClose className="close-icon" />
        </div>
        <div className="contact-us-main">
          <p className="title">
            Contáctanos
          </p>
          <div className="info-container">
            <div className="input-container">
              <p>
                Nombre
              </p>
              <input
                type="text"
                value={name}
                onChange={handleChange}
                placeholder="Alfaproducts"
              />
            </div>
            <div className="input-container">
              <p>
                Email
              </p>
              <input
                type="text"
                value={email}
                onChange={(e) => {setEmail(e.target.value)}}
                placeholder="ventas2@alfaproducts.cl"
              />
            </div>
            <div className="input-container">
              <p>
                Asunto
              </p>
              <input
                type="text"
                value={subject}
                onChange={(e) => {setSubject(e.target.value)}}
                placeholder="Ventas Alfaproducts"
              />
            </div>
            <div className="input-container">
              <p>
                Contenido
              </p>
              <textarea
                value={content}
                onChange={(e) => {setContent(e.target.value)}}
                placeholder="Contenido..."
              />
            </div>
          </div>
          <button
            className={(isValid) ? '' : 'disabled'}
            onClick={() => handleSubmit()}
          >
            Envíar
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(ContactUs);
