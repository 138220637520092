import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import './styles/App.scss';

import FamiliesView from './views/FamiliesView';
import IrrigationView from './views/IrrigationView';
import LandingView from './views/LandingView';
import LandingMobileView from './views/LandingMobileView';
import ValvesView from './views/ValvesView';

function App() {
  useEffect(() => {
    if (window.innerWidth > 0) {
      if (window.location.pathname !== '/' && window.innerWidth < 768) {
        window.location.href = 'https://alfaproducts.cl';
      }
    }
  }, [window.innerWidth]);

  return (
    <Router>
      <Routes>
        <Route 
          path="/"
          element={(window.innerWidth < 768) ? <LandingMobileView /> : <LandingView />}
        />
        <Route 
          path="/product/hoses/:id"
          element={<FamiliesView />}
        />
        <Route 
          path="/product/valves/:id"
          element={<ValvesView />}
        />
        <Route 
          path="/product/irrigation/:id"
          element={<IrrigationView />}
        />
      </Routes>
    </Router>
  );
}

export default App;
