import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../styles/ProductsStyles.scss';

import {  families, familyChildren } from '../constants';

import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import LogoAlfahoses from '../assets/images/logos/alfa-hoses.png';

import { BsDot } from "react-icons/bs";

function FamiliesView() {
  // Params
  const { id } = useParams();

  // States
  const [showContactUs, setShowContactUs] = useState(false);
  const [mounted, setMounted] = useState(false);

  const setShowContactUsCallback = useCallback((value) => {
    setShowContactUs(value);
  }, []);

  const dataTable = () => {
    const table = []
    for (let i = 0; i < familyChildren[id].data[0].values.length; i++) {
      const row = []
      for (let j = 0; j < familyChildren[id].data.length; j++) {
        row.push(familyChildren[id].data[j].values[i]);
      }
      table.push(row)
    }
    return table;
  }

  useEffect(() => {
    setMounted(true);
  }, [id])

  return (mounted) && (
    <div className="product-container">
      <Navbar
        setShowContactUs={setShowContactUsCallback}
      />
      <div className="image-title">
        <Carousel className="carousel">
          {families[id].images.map((image, i) => (
            <img
              key={i}
              src={image}
              alt="Alfaproducts"
            />
          ))}
        </Carousel>
        <div className="info">
          <img
            alt="Alfahoses logo"
            src={LogoAlfahoses}
            className="logo"
          />
          <p className="product-name">
            {families[id].product}
          </p>
          {families[id].description.map((text, i) => (
            <div
              key={i}
              className="text"
            >
              <BsDot />
              {text}
            </div>
          ))}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {familyChildren[id].data.map((data, i) => (
              <th
                key={i}
                className="header"
              >
                {`${data.name} (${data.measure})`}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataTable().map((row, i) => (
            <tr key={i}>
              {row.map((data, j) => (
                <td className="cell" key={j}>
                  {data}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Footer
        setShowContactUs={setShowContactUsCallback}
      />
      {(showContactUs) && (
        <ContactUs
          setShowContactUs={setShowContactUsCallback}
        />
      )}
    </div>
  );
}

export default FamiliesView;
